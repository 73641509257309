import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TermsManager } from './terms-manager';

@Injectable()
export class TermsResolver implements Resolve<any> {
    
    constructor(private termsManager: TermsManager) {
    }
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.termsManager.validate();
    }
    
}
