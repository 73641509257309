import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConfig } from '../config';
import { Observable } from 'rxjs';

@Injectable()
export class BaseUriInterceptor implements HttpInterceptor {
    
    constructor(private config: HttpConfig) {
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const dupReq = req.clone();
        
        return next.handle(dupReq.clone({url: this.config.baseUri + dupReq.url}));
    }
}
