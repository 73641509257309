import { Directive, ElementRef, OnInit, Renderer } from '@angular/core';

declare var mDropdown: any;
declare var $: any;

@Directive({
    selector: '[dropdown]'
})
export class DropdownDirective implements OnInit {
    
    constructor(private elRef: ElementRef, private renderer: Renderer) {
    
    }
    
    ngOnInit() {
        const dropdown = new mDropdown(this.elRef.nativeElement);
        
        $(this.elRef.nativeElement).find('.btn').on('click', () => {
            dropdown.toggle();
        });
    }
}
