import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LocalStorageService {
    
    private observer: Subject<any> = new Subject<any>();
    
    public has(id: string): boolean {
        const val: any = localStorage.getItem(id);
        return val !== null && val.length > 0;
    }
    
    public empty(id: string): boolean {
        return !this.has(id);
    }
    
    public get(id: string): any {
        try {
            return JSON.parse(localStorage.getItem(id));
        } catch (e) {
            return null;
        }
    }
    
    public save(id: string, value: any): void {
        localStorage.setItem(id, value ? JSON.stringify(value) : null);
        this.observer.next(this.get(id));
    }
    
    public clear(id: string): void {
        localStorage.removeItem(id);
        this.observer.next(this.get(id));
    }
    
    public getObserver(): Subject<any> {
        return this.observer;
    }
}
