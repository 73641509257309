import { Component, OnInit } from '@angular/core';
import { SessionManager } from './module/auth/service/session-manager';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TermsManager } from './module/terms/terms-manager';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'cdnmanager';

    constructor(private sessionManager: SessionManager,
                private termsManager: TermsManager,
                private route: ActivatedRoute, translate: TranslateService) {

        translate.setTranslation('en', {});
        translate.setTranslation('it', {
            'Login': 'Accedi',
            'Logged in as': 'Accesso effettuato come: ',
            'Forget password?': 'Reimposta la password',
            'Your live stream': 'Il tuo video in diretta',
            'My Account' : 'Gestione account',
            'Publish stream': 'Pubblica stream',
            'Source stream': 'Stream sorgente',
            'Wait...': 'Attendere...',
            'Send': 'Avanti',
            'Incorrect password. Password must be at least 6 characters': 'Password non corretta. La password deve contenere almeno 6 caratteri',
            'Confirm password': 'Conferma password',
            'Reset': 'Reimposta',
            'Your Facebook account is not connected. Login with Facebook first': 'Il tuo account Facebook non è collegato. Eseguire prima l\'accesso',
            'List of streams': 'Lista streams',
            'Stopping': 'Arresto in corso',
            'Stop': 'Arresto',
            'Title': 'Titolo',
            'Description': 'Descrizione',
            'Location': 'Posizione',
            'Page': 'Pagina',
            'Stream to all pages': 'Pubblica su tutte le pagine',
            'Privacy': 'Visibilità',
            'Stream type': 'Tipo di stream',
            'Stop stream': 'Arresta stream',
            'Go to overview': 'Vai alla panoramica',
            'My account': 'Gestione account',
            'Help': 'Aiuto',
            'Terminate all active stream(s)': 'Termina tutti gli stream(s) attivi',
            'Loading active streams... Please wait': 'Caricamento streams attivi... Prego attendere',
            'Cancel': 'Cancella',
            'Terminating': 'Terminazione',
            'Terminate': 'Termina',
            'Edit': 'Modifica',
            'Name': 'Nome',
            'Email': 'Email',
            'Password': 'Password',
            'Save': 'Salva',
            'Your Youtube account is not connected. Login with YouTube first': 'Il tuo account YouTube non è collegato. Eseguire prima l\'accesso',
            'Format': 'Formato',
            'Actions': 'Azioni',
            'Stream delete': 'Termina stream',
            'Close': 'Chiudi',
            'Deleting': 'Cancellazione',
            'Delete': 'Cancella',
            'Canceling': 'Cancellazione',
            'Password reset instructions have been sent to your email': 'Le istruzioni per reimpostare la password sono state trasmesse al vostro indirizzo mail',
            'Failed to send password reset instructions': 'Non è stato possibile trasmettere le istruzioni per reimpostare la password',
            'Enter email': 'Digitare indirizzo email',
            'Could not start session': 'Non è possibile iniziare la sessione',
            'Invalid email or password': 'email o password invalidi',
            'Enter email and password': 'Digitare indirizzo email e password',
            'Password has been changed': 'La password è stata cambiata',
            'Failed to change password': 'Non è stato possibile cambiare la password',
            'Enter new password': 'Digitare la nuova password',
            'Confirm new password': 'Conferma nuova password',
            'Password too short (min. 6 characters)': 'La password è troppo corta (digitare almeno 6 caratteri)',
            'Streams terminated': 'Streams terminati',
            'Failed to terminate all streams. Try again': 'Non è stato possibile terminare tutti gli streams. Riprovare',
            'User not found': 'Utente non trovato',
            'Form contains errors': 'I campi contengono degli errori',
            'Saved': 'Salvato',
            'Failed to save': 'Non è stato possibile salvare',
            'Stream stop': 'Termina Stream',
            'Choose stream': 'Scegli stream',
            'End stream': 'Termina stream',
            'Create stream': 'Crea stream',
            'Publish': 'Pubblica',
            'Streams': 'Streams',
            'Loading. Please wait...': 'Caricamento. Prego attendere...',
            'Only me': 'Solo io',
            'Friends': 'Amici',
            'Friends of friends': 'Amici di amici',
            'Public': 'Pubblico',
            'Standard Stream (e.g. event)': 'Evento in diretta standard',
            'Continuous Stream (24/7 Streams. FB will not keep recordings)': 'Diretta video 24/7 (non verrà registrata)',
            'Private': 'Privato',
            'Unlisted': 'Non selezionato',
            'Timeline': 'Diario',
            'Do you want to stop stream': 'Vuoi terminare lo stream',
            'Your stream is created in Youtube. It will be available in a few moments. Please wait...': 'Il tuo stream è in fase di creazione in YouTube e sarà disponibile a breve. Prego attendere...',
            'Creating': 'Creazione',
            'Your stream is live and available in Youtube.': 'Il tuo stream è attivo e disponibile in YouTube.',
            'Logout': 'Disconnetti',
            'Your Youtube account is not connected. Login with Youtube first': 'Il tuo account YouTube non è collegato. Eseguire prima I\'accesso',
            'Stream created': 'Stream creato',
            'Stream name': 'Nome stream',
            'Stream stopped': 'Stream terminato',
            'Ending': 'Terminazione',
            'Stream deleted': 'Stream terminato',
            'Changes': ' Modifiche',
            'I read the terms': 'Ho letto ed approvato',
            'Terms & conditions': 'Termini e condizioni d\'uso',
            'I read the data term': 'Ho letto ed accetto i termini per il trattamento dei dati',
            'I read the term': 'Ho letto ed accetto i termini del servizo',
            'Accept': 'Accetta',
            'Do you want to delete stream': 'Vuoi veramente terminare lo stream',
            'I read the data protection term': '',
            'Do you want to terminate stream': 'Vuoi terminare lo stream',
            'Source destination': 'Stream destinazione',
            'Application': 'Applicazione',
            'Port': 'Porta',
            'Are you sure you want to restart channel:': 'Sei sicuro di voler riavviare il canale:',
            'Warning: All users for this channel will be disconnected for few seconds.': 'Attenzione: Tutti gli utenti saranno disconnessi per alcuni secondi.',
            'Dest. stream': 'Stream dest.',
            'Dest. application': 'Applicazione',
            'Dest. host': 'Host',
            'Dest. port': 'Porta',
            'Creation date': 'Data creazione',
            'Status': 'Stato',
            'Active': 'Attivo',
            'Stream destination name': 'Nome stream destinazione',
            'Target': 'Destinazione',
            'Waiting': 'Attesa..',
            'Starting': 'Avvio',
            'Disabled': 'Disabilitato',
            'Error': 'Errore',
            'ACTIVE': 'ATTIVO',
            'WAITING': 'ATTESA..',
            'STARTING': 'AVVIO',
            'DISABLED': 'DISABILITATO',
            'ERROR': 'ERRORE',
            'Disable': 'Disabilita',
            'Stream disable': 'Disabilita stream',
            'Stream enable': 'Attivare stream',
            'Do you want to disable stream': 'Vuoi disabilitare lo stream',
            'Do you want to enable stream': 'Vuoi attivare lo stream',
            'Disable this stream target': 'Disabilita questo stream',
            'Enable this stream target': 'Abilita questo stream',
            'Enable': 'Abilita',
            'No active streams found': 'Nessuno stream attivo trovato',
            'Following stream(s) will be terminated. Do you want to proceed?': 'I seguenti stream(s) verranno terminati. Vuoi procedere?',
            'Target name': 'Nome destinazione',
            'Created at': 'Data creazione',
            'Terminate all stream(s)': 'Termina tutti gli stream(s) attivi',
            'Delete this stream target': 'Elimina questo stream',
            'Target already exists': 'Destinazione già esistente',
            'Target(s)': 'Destinazioni',
            'Required fields': 'Campi obbligatori',
            'Incorrect start date time': 'Data/Ora di inizio non corretta',
            'Start date time must be in future': 'La data-ora di inizio deve essere futura',
            'Stop date time must be in future': 'Selezionare una data di fine futura',
            'Incorrect stop date time': 'Data/Ora di fine non corretta',
            'Stop date time must be bigger than start date time': 'La data/ora di fine deve essere successiva alla data di inizio',
            'Stream scheduled': 'Stream schedulato',
            'Failed to schedule stream': 'Errore nella creazione della schedulazione',
            'Schedule stream': 'Schedula stream',
            'Schedule Name': 'Nome schedulazione',
            'YouTube Title': 'Titolo YouTube',
            'YouTube Description': 'Descrizione YouTube',
            'YouTube Resolution': 'Risoluzione Youtube',
            'YouTube Frame rate': 'Frequenza fotogrammi YouTube',
            'YouTube Privacy': 'Visibilità YouTube',
            'Enabled': 'Abilitato',
            'Run once': 'Esegui una volta',
            'Repeat': 'Esegui ripetuto',
            'Date time': 'Data / Ora',
            'Start at': 'Avvio alle',
            'Stop at': 'Stop alle',
            'Add current date': 'Aggiungi data giornaliera',
            'Add schedule': 'Aggiungi schedulazione',
            'Scheduling': 'Schedulazione',
            'Recurrence': 'Ricorrenza',
            'Schedule Recurrence': 'Ricorrenza schedulazione',
            'Schedule status': 'Stato schedulazione',
            'Time': 'Ora',
            'Disable this schedule': 'Disabilita schedulazione',
            'Enable this schedule': 'Abilita schedulazione',
            'Delete this schedule': 'Cancella schedulazione',
            'Do you want to cancel schedule': 'Vuoi cancellare questa schedulazione',
            'Delete schedule': 'Cancella schedulazione',
            'Do you want to enable schedule': 'Vuoi abilitare questa schedulazione',
            'Enable schedule': 'Abilita schedulazione',
            'Do you want to disable schedule': 'Vuoi disabilitare questa schedulazione',
            'Disable schedule': 'Disabilita schedulazione',
            'Enabling': 'Abilitazione',
            'Disabling': 'Disabilitazione',
            'RTMP Stream Name': 'nome stream RTMP ',
            'RTMP Application': 'applicazione RTMP',
            'RTMP Host': 'server RTMP',
            'RTMP Port': 'porta RTMP',
            'RTMP Username': 'User',
            'RTMP Password': 'Password',
            'Send SSL': 'Trasmetti SSL',
            'Facebook Title': 'Titolo Facebook',
            'Facebook Description': 'Descrizione Facebook',
            'Facebook Location': 'Posizione Facebook',
            'Facebook Privacy': 'Visibilità Facebook',
            'Type': 'Tipo',
            'Resolution': 'Risoluzione',
            'Frame rate': 'Frequenza fotogrammi',
            'From': 'Dal',
            'To': 'Al',
            'Starts': 'Inizio',
            'Stops': 'Fine',
            'Date': 'Data',
            'Schedule deleted': 'Schedulazione cancellata',
            'Sun': 'Dom',
            'Mon': 'Lun',
            'Tue': 'Mar',
            'Wed': 'Mer',
            'Thu': 'Gio',
            'Fri': 'Ven',
            'Sat': 'Sab',
            'List': 'Lista',
            'Select at least one day': 'Selezionare almeno un giorno',
            'Expired': 'Scaduto',
            'Copied to clipboard': 'Copiato negli appunti',
            'Preset deleted': 'Preimpostazione cancellata',
            'Could not save preset': 'Non è possibile salvare la preimpostazione',
            'API Presets': 'Modelli Predefiniti',
            'Do you want to delete preset': 'Vuoi cancellare la preimpostazione',
            'Grant permission to manage your YouTube Live Streams': 'Concedi autorizzazione per gestire live stream YouTube',
            'YouTube Account': 'Account YouTube',
            'Grant date/time': 'Data/Ora autorizzazione',
            'Revoke': 'Revoca',
            'Grant permission to manage your Facebook Live': 'Concedi autorizzazione per gestire live stream Facebook',
            'Select page you want to grant access to': 'Seleziona pagina dove vuoi autorizzare accesso',
            'Grant access': 'Autorizza accesso',
            'Facebook Destination': 'Destinazione Facebook',
            'Do you want to revoke access to': 'Vuoi revocare accesso a',
            'Your API KEY': 'La tua chiave API',
            'Generate API KEY': 'Genera chiave API',
            'Facebook Page': 'Pagina Facebook',
            'YouTube access granted': 'Accesso a YouTube autorizzato',
            'Could not grant access. Try again': 'Impossibile concedere accesso. Riprova',
            'Infinite': 'Perpetuo',
            'Add': 'Aggiungi',
            'Never stop': 'Perpetuo',
            'API KEY created': 'Chiave API creata',
            'Access revoked': 'Accesso revocato',
            'Facebook access granted': 'Accesso Facebook autorizzato',
            'Permission': 'Permessi',
            'Presets': 'Predefiniti',
            'Automation settings': 'Gestisci permessi',
            'Automation': 'Autorizzazioni',
            'Do you want to generate new api key': 'Vuoi generare una nuova chiave API',
            'Generate': 'Generare',
            'Generating': 'Generazione',
            'Generate new api key': 'Genera chiave API',
            'Delete preset': 'Cancella preimpostazione',
            'Enter title': 'Digitare titolo',
            'Enter description': 'Digitare descrizione',

            'Delete my account': 'Elimina il mio account',
            'There was an error while deleting your account. Contact support': 'Errore durante eliminazione account. Contattare il supporto tecnico',
            'Account deleted': 'Account eliminato',
            'Delete account permanently': 'Elimina account permanentemente',
            'Delete account failed': 'Eliminazione account fallita',
            'You will receive email with link to confirm your account removal': 'Riceverai un email con il link per confermare eliminazione del tuo account',
            'I acknowledge that my account will be permanently deleted': 'Sono consapevole che il mio account sarà eliminato definitivamente',
            'Delete account. Operation cannot be undone': 'Elimina account. Operazione non annullabile',
            'Delete social media integrations': 'Elimina le integrazioni e permessi dei social media',
            'Delete data': 'Elimina',
            'Delete social media': 'Elimina social media',
            'Do you want to delete social media integrations': 'Vuoi veramente elimiare integrazioni e permessi dei social media',
            'Delete account': 'Elimina account',
            'Your social data were removed': 'I tuoi dati social sono stati eliminati',

            'Last 365 days': 'Ultimi 365 giorni',
            'Today': 'Oggi',
            'Yesterday': 'Ieri',
            'Last 7 days': 'Ultimi 7 giorni',
            'Last 30 days': 'Ultimi 30 giorni',
            'Last hour': 'Ultima ora',
            'Last 4 hours': 'Ultime 4 ore',
            'Select or enter date/time range': 'Seleziona intervallo data/ora',

            'Network': 'Rete',
            'You can select max 10 destinations': 'Puoi selezionare massimo 10 destinazioni',

            'Live social streaming has never been easier': 'Il live social streaming non è mai stato così facile',
            'Live streaming on Facebook': 'Live streaming su Facebook',
            'Our application offers an accurate and safe way to send your stream to Facebook Live. Just select the source stream, provide the title, description, choose the destination on Facebook which can be the diary or the pages, and you are ready to go!': 'La nostra applicazione offre un modo accurato e sicuro per inviare il tuo stream a Facebook Live. Basta selezionare il flusso sorgente, fornire titolo, descrizione, scegliere la destinazione su Facebook che può essere il diario o le pagine, e sei pronto per partire!',
            'See how we treat your Facebook data': 'Guarda come trattiamo i tuoi dati Facebook',
            'Live streaming on YouTube': 'Live streaming su YouTube',
            'With the same method, our application provides an easy way to set up live streaming on YouTube. You can publish both private and public live with the defined resolution and frame rate, which will immediately appear on YouTube!': 'Con la stesso metodo, la nostra applicazione fornisce un modo semplice per configurare il live streaming su YouTube. Puoi pubblicare sia il live privato che pubblico con la risoluzione e frequenza dei fotogrammi definita, che apparirà immediatamente su YouTube!',
            'Find out how we process your YouTube data.': 'Scopri come trattiamo i tuoi dati di YouTube.',
            'Live streaming to your RTMP destination': 'Live streaming sulla vostra destinazione RTMP',
            'In addition to social live streaming you can easily transfer your live stream to custom destinations, for later distribution on other platforms.': 'Oltre al social live streaming puoi facilmente trasferire il tuo live stream su destinazioni personalizzate, per la successiva distribuzione su altre piattaforme.',
            'Plan your events': 'Programma i tuoi eventi',
            'The scheduling function automatically starts the live stream to the desired Facebook®, YouTube® or RTMP destination. You decide when the stream should be activated and the integrated scheduler will take care of it. You can choose the exact date and time when the streaming will take place, which can be a single event or recursive.': 'La funzione di programmazione ad orario avvia automaticamente il live stream verso la destinazione Facebook®, YouTube® o RTMP voluta. Decidi tu quando lo stream si dovrà attivare e lo scheduler integrato se ne occuperà. Puoi scegliere la data e l\'ora esatta in cui avverrà lo streaming, che potrà essere un evento singolo oppure recursivo.',
            'Ready to get started?': 'Pronto per iniziare?',
            'Contact us now!': 'Contattaci ora!',

            'Exceeded maximum limit of 10 simultaneous destinations, delete some': 'Limite massimo 10 destinazioni simultanee superato, cancellare alcune',
            'destinations': 'destinazioni',
            'and try again': 'e riprovare'
        });
        const userLang = navigator['language'] || navigator['userLanguage'];

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(userLang ? userLang.slice(0, 2) : 'it');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(userLang ? userLang.slice(0, 2) : 'it');
    }

    ngOnInit(): void {
        this.sessionManager.start().subscribe();
        this.termsManager.start().subscribe();
    }
}
