import { Component, OnInit } from '@angular/core';
import { ForgetPasswordForm } from './forget-password.form';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '../../../shared/ui/notification.service';

@Component({
    selector: 'auth-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
    
    protected sending: boolean = false;
    year: number = new Date().getFullYear();
    protected form: FormGroup;
    protected sendPromise: Subscription;
    protected submitListener: Subject<any> = new Subject<any>();
    
    constructor(form: ForgetPasswordForm,
                protected httpClient: HttpClient,
                protected notification: NotificationService,
                protected router: Router) {
        this.form = form.buildForm();
    }
    
    ngOnInit(): void {
        document.getElementById('body').classList.add(...'m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default'.split(' '));
        document.getElementById('body').style.backgroundImage = 'url(/assets/img/bg.jpg)';
    }
    
    submit(): void {
        if (this.form.valid) {
            this.sending = true;
            this.sendPromise = this.httpClient.post('/auth/password/email', this.form.value)
                .subscribe((response: any) => {
                    this.submitListener.next(response);
                    if (response.result) {
                        this.notification.success('Password reset instructions have been sent to your email');
                    } else {
                        this.notification.error('Failed to send password reset instructions');
                    }
                    this.sending = false;
                    return response;
                }, (error: HttpErrorResponse) => {
                    this.sending = false;
                    return error;
                });
        } else {
            this.notification.error('Enter email');
        }
    }
}
