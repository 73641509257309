import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class ResetPasswordForm {
    
    constructor(private formBuilder: FormBuilder) {
    }
    
    public buildForm(): FormGroup {
        return this.formBuilder.group({
            password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
            password_confirmation: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
        });
    }
}
