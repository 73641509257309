import { Component, OnInit } from '@angular/core';
import { LoginForm } from './login.form';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { SessionTokenService } from '../../service/session-token';
import { SessionManager } from '../../service/session-manager';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '../../../shared/ui/notification.service';
import { TermsManager } from '../../../terms/terms-manager';

declare var WebFont: any;

@Component({
    selector: 'auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    year: number = new Date().getFullYear();
    protected loggingIn: boolean = false;
    protected form: FormGroup;
    protected savePromise: Subscription;
    protected submitListener: Subject<any> = new Subject<any>();

    constructor(form: LoginForm,
                protected httpClient: HttpClient,
                protected notification: NotificationService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected termsManager: TermsManager,
                protected sessionService: SessionTokenService,
                protected sessionManager: SessionManager) {
        this.form = form.buildForm();
        if (this.sessionService.isAuthenticated()) {
            this.router.navigate(['/app']);
        }
    }

    ngOnInit(): void {
        document.getElementById('body').classList.add(...'m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default'.split(' '));
        document.getElementById('body').style.backgroundImage = 'url(/assets/img/bg.jpg)';
    }

    submit(): void {
        if (this.form.valid) {
            this.loggingIn = true;
            this.savePromise = this.httpClient.post('/auth/login', this.form.value)
                .subscribe((response: any) => {
                    this.submitListener.next(response);
                    response.created_at = Date.now() / 1000;
                    this.sessionService.persist(response);
                    this.sessionManager.start().subscribe(() => {
                        this.router.navigate(['/app']);
                    }, (error: any) => {
                        this.notification.error('Could not start session');
                    });
                    this.termsManager.start().subscribe();

                    return response;
                }, (error: HttpErrorResponse) => {
                    this.notification.error('Invalid email or password');
                    this.loggingIn = false;
                    return error;
                });
        } else {
            this.notification.error('Enter email and password');
        }
    }
}
