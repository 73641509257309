import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TermsManager } from '../../../terms/terms-manager';

@Injectable()
export class TermsGuard implements CanActivate {
    
    constructor(private termsManager: TermsManager,
                private router: Router) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.termsManager.isTermsRead()
            .pipe(
                map((response: any) => {
                    return !!response.isRead;
                })
            );
    }
}
