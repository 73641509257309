import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxLoadingModule } from 'ngx-loading';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';
import { APP_BASE_HREF } from '@angular/common';
import { AuthServiceModule } from './module/auth/auth.module';
import { SharedModule } from './module/shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { TermsServiceModule } from './module/terms/terms.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ChartsModule } from 'ng2-charts';
defineLocale('it', itLocale);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(AppRoutes),
        SharedModule,
        AuthServiceModule,
        TermsServiceModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        NgxLoadingModule.forRoot({}),
        TooltipModule.forRoot({}),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: false,
            timeOut: 3500,
            onActivateTick: true
        }),
        TranslateModule.forRoot({
            useDefaultLang: true,
        }),
        TimepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
        ClipboardModule,
        NgxMaterialTimepickerModule,
        ChartsModule
    ],
    providers: [
        {provide: APP_BASE_HREF, useValue : '/' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
