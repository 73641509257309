import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionTokenService } from '../../../auth/service/session-token';

@Injectable()
export class SessionTokenGuard implements CanActivate {

    constructor(private sessionService: SessionTokenService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.sessionService.isAuthenticated()) {
            return true;
        }
        const url = '/' + route.url.join('/');
        switch (url) {
            case '':
            case '/login':
            case '/':
            case '/policy':
            case '/cookie':
                return true;
            default:
                this.router.navigate(['/login']);
                break;
        }
        return false;
    }
}
