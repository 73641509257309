import { Routes } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';

export const AuthRoutes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: 'forget',
        component: ForgetPasswordComponent
    },
    {
        path: 'reset/:token',
        component: ResetPasswordComponent
    }
];
