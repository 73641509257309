import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthTokenInterceptor } from './interceptor/auth-token';
import { BaseUriInterceptor } from './interceptor/base-uri';
import { HttpConfig } from './config';
import { environment } from '../../../../environments/environment';

@NgModule({
    providers: [
        {
            provide: HttpConfig,
            useFactory: () => {
                const config = new HttpConfig();
                return <HttpConfig>Object.assign(config, {
                    baseUri: environment.http.baseUri,
                    authorization: environment.http.authorization
                });
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseUriInterceptor,
            multi: true
        }
    ],
    imports: [
        HttpClientModule
    ]
})
export class HttpModule {

}
