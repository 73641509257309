import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Observer, Subscription } from 'rxjs';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { flatMap, map } from 'rxjs/operators';
import { GlobalEventBus } from '../shared/event/global-bus';

@Injectable()
export class TermsManager implements OnInit, OnDestroy {

    private subscription: Subscription;

    constructor(private route: ActivatedRoute,
                private httpClient: HttpClient,
                private eventBus: GlobalEventBus,
                private router: Router) {
    }

    ngOnInit(): void {
        this.eventBus.bind('logout', () => {
            this.ngOnDestroy();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public isTermsRead(): Observable<any> {
        return this.httpClient.get<any>('/terms/is-read');
    }

    public validate(): Observable<any> {
        return this.isTermsRead()
            .pipe(
                map(
                    (response: any) => {
                        if (!response.isRead) {
                            return this.showTerms();
                        } else {
                            return true;
                        }
                    }, (e: any) => {
                    }
                )
            );
    }

    public start(): Observable<any> {
        return Observable.create((obs: Observer<any>) => {
            this.isTermsRead().subscribe(
                (response: any) => {
                    obs.next(response);
                    this.subscription = interval(60 * 1000)
                        .pipe(
                            flatMap(() => this.validate()),
                        ).subscribe();
                    if (!response.isRead) {
                        this.showTerms();
                    }
                }, (e: any) => {
                }
            );
        });
    }

    public showTerms() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if ((<any>this.route.snapshot)._routerState.url.indexOf('/terms') !== 0) {
            this.router.navigate(['/terms']);
        }
        return false;
    }
}
