import { NgModule } from '@angular/core';

import { LoginComponent } from './component/login/login.component';
import { RouterModule } from '@angular/router';
import { AuthRoutes } from './auth.routes';
import { LoginForm } from './component/login/login.form';
import { SessionManager } from './service/session-manager';
import { SessionTokenService } from './service/session-token';
import { SessionResolver } from './service/session-resolver';
import { SharedModule } from '../shared/shared.module';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ForgetPasswordForm } from './component/forget-password/forget-password.form';
import { ResetPasswordForm } from './component/reset-password/reset-password.form';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        SessionTokenService,
        SessionManager,
        SessionResolver
    ]
})
export class AuthServiceModule {

}

@NgModule({
    declarations: [
        LoginComponent,
        ForgetPasswordComponent,
        ResetPasswordComponent
    ],
    imports: [
        RouterModule.forChild(AuthRoutes),
        AuthServiceModule,
        SharedModule
    ],
    providers: [
        LoginForm,
        ForgetPasswordForm,
        ResetPasswordForm,
    ]
})
export class AuthModule {

}
