import { Routes } from '@angular/router';
import { SessionTokenGuard } from './module/shared/router/guard/session';

export const AppRoutes: Routes = [
    {
        path: '',
        loadChildren: './module/static/static.module#StaticModule'
    },
    {
        path: 'app',
        loadChildren: './module/dashboard/dashboard.module#DashboardModule',
        canActivate: [SessionTokenGuard]
    },
    {
        path: 'login',
        loadChildren: './module/auth/auth.module#AuthModule'
    },
    {
        path: 'terms',
        loadChildren: './module/terms/terms.module#TermsModule'
    }
];
