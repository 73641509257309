import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../shared/storage/local-storage.service';

@Injectable()
export class SessionTokenService {
    
    static STORAGE_KEY = '__gpt__';
    
    constructor(private localStorageService: LocalStorageService) {
    }
    
    persist(token: any): void {
        this.localStorageService.save(SessionTokenService.STORAGE_KEY, token);
    }
    
    destroy(): void {
        this.localStorageService.clear(SessionTokenService.STORAGE_KEY);
    }
    
    isAuthenticated(): boolean {
        const token = this.localStorageService.get(SessionTokenService.STORAGE_KEY);
        if (!token) {
            return false;
        }
        const now = Date.now() / 1000;
        if (token.created_at + token.expires_in < now) {
            return false;
        }
        return true;
    }
    
    getToken(): any {
        return this.localStorageService.get(SessionTokenService.STORAGE_KEY);
    }
}
