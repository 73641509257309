import { Routes } from '@angular/router';
import { SessionTokenGuard } from '../shared/router/guard/session';
import { PreviewComponent } from './preview/preview.component';

export const TermsRoutes: Routes = [
    {
        pathMatch: 'full',
        path: '',
        component: PreviewComponent,
        canActivate: [SessionTokenGuard]
    }
];
