import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Observer, Subscription } from 'rxjs';

import { SessionTokenService } from './session-token';
import { Injectable } from '@angular/core';
import { flatMap, map } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/storage/local-storage.service';


@Injectable()
export class SessionManager {

    private subscription: Subscription;

    constructor(private sessionToken: SessionTokenService,
                private localStorageService: LocalStorageService,
                private route: ActivatedRoute,
                private httpClient: HttpClient,
                private router: Router) {
    }

    public getIdentity(): Observable<any> {
        return this.httpClient.get<any>('/auth/me');
    }

    public validate(): Observable<any> {
        return this.getIdentity()
            .pipe(
                map(
                    (response: any) => {
                        return response;
                    },
                    (error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            this.destroy();
                        }
                        return error;
                    }
                )
            );
    }

    public start(): Observable<any> {
        return Observable.create((obs: Observer<any>) => {
            this.getIdentity().subscribe(
                (response: any) => {
                    this.localStorageService.save('sessid', response);
                    obs.next(response);
                    this.subscription = interval(60 * 1000)
                        .pipe(
                            flatMap(() => this.validate()),
                        ).subscribe();
                },
                (error) => {
                    this.destroy();
                    obs.error(error);
                }
            );
        });
    }

    public destroy() {
        this.sessionToken.destroy();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        const url = (<any>this.route.snapshot)._routerState.url;
        switch (url) {
            case '':
            case '/login':
            case '/':
            case '/policy':
            case '/cookie':
                break;
            default:
                this.router.navigate(['/login']);
                break;
        }
    }
}
