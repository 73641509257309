import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class LoginForm {
    
    constructor(private formBuilder: FormBuilder) {
    }
    
    public buildForm(): FormGroup {
        return this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }
}
