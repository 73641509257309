import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'embed-html',
    templateUrl: './embed-html.component.html',
    styleUrls: ['./embed-html.component.scss']
})
export class EmbedHtmlComponent implements OnChanges {

    @Input() html: string = '';
    @Input() customWidth: string = '100%';
    @Input() customHeight: string = '100%';
    
    constructor(private sanitizer: DomSanitizer) {
    }
    
    
    ngOnChanges(changes: SimpleChanges): void {
        this.setHtml(changes.html.currentValue);
    }
    
    setHtml(html: string): void {
    
    }
}
