import { NgModule } from '@angular/core';

import { LocalStorageService } from './storage/local-storage.service';
import { SessionTokenGuard } from './router/guard/session';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from './http/http.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UiModule } from './ui/ui.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { AdminGuard } from './router/guard/admin';
import { TermsGuard } from './router/guard/terms';
import { GlobalEventBus } from './event/global-bus';

@NgModule({
    declarations: [],
    providers: [
        LocalStorageService,
        SessionTokenGuard,
        AdminGuard,
        TermsGuard,
        GlobalEventBus
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        UiModule,
        NgxLoadingModule,
        ToastrModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        UiModule,
        NgxLoadingModule,
        ToastrModule,
        TranslateModule
    ]
})
export class SharedModule {

}
