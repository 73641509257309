import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionManager } from './session-manager';

@Injectable()
export class SessionResolver implements Resolve<any> {
    
    constructor(private sessionManager: SessionManager) {
    }
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.sessionManager.validate();
    }
    
}
