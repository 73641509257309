import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventBus {

    protected events: Map<String, Function[]> = new Map();

    public emit(eventName: string, ...args: any[]): void {
        if (!this.events[eventName]) {
            return;
        }
        for (const event of this.events[eventName]) {
            event.apply(undefined, args);
        }
    }

    public bind(eventName: string, fn: Function): void {
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(fn);
    }

    public unbind(eventName: string): void {
        this.events.delete(eventName);
    }
}
