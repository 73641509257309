import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionManager } from '../../auth/service/session-manager';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalEventBus } from '../../shared/event/global-bus';

@Component({
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

    protected terms: any = {};
    protected termsAccepted: boolean = false;
    protected dataProtectionAccepted: boolean = false;

    constructor(private httpClient: HttpClient,
                private sessionManager: SessionManager,
                private router: Router,
                private eventBus: GlobalEventBus,
                private sanitizer: DomSanitizer) {

    }

    ngOnInit(): void {
        this.httpClient.get('/terms/get')
            .subscribe(
                (response: any) => {
                    this.terms = response;
                    this.terms.content = this.sanitizer.bypassSecurityTrustHtml(this.terms.content);
                }
            );
    }

    acceptTerms() {
        this.termsAccepted = !this.termsAccepted;
    }

    acceptDataProtection() {
        this.dataProtectionAccepted = !this.dataProtectionAccepted;
    }

    readTerm() {
        if (this.termsAccepted && this.dataProtectionAccepted) {
            this.httpClient.put('/terms/read', {})
                .subscribe((response) => {
                    this.router.navigate(['/app']);
                });
        }
    }

    logout() {
        this.sessionManager.destroy();
        this.eventBus.emit('logout');
        this.router.navigate(['/login']);
    }
}
