import { Component, OnInit } from '@angular/core';
import { ResetPasswordForm } from './reset-password.form';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '../../../shared/ui/notification.service';
import { SessionManager } from '../../service/session-manager';
import { SessionTokenService } from '../../service/session-token';

@Component({
    selector: 'auth-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    
    protected resetting: boolean = false;
    year: number = new Date().getFullYear();
    protected form: FormGroup;
    protected sendPromise: Subscription;
    protected submitListener: Subject<any> = new Subject<any>();
    
    constructor(form: ResetPasswordForm,
                protected route: ActivatedRoute,
                protected httpClient: HttpClient,
                protected notification: NotificationService,
                protected sessionService: SessionTokenService,
                protected sessionManager: SessionManager,
                protected router: Router) {
        this.form = form.buildForm();
    }
    
    ngOnInit(): void {
        document.getElementById('body').classList.add(...'m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default'.split(' '));
        document.getElementById('body').style.backgroundImage = 'url(/assets/img/bg.jpg)';
    }
    
    submit(): void {
        const values = this.form.value;
        if (this.form.valid) {
            this.resetting = true;
            values.token = this.route.snapshot.params.token;
            this.sendPromise = this.httpClient.post('/auth/password/reset', this.form.value)
                .subscribe((response: any) => {
                    this.submitListener.next(response);
                    if (response.result) {
                        this.notification.success('Password has been changed');
                        this.sessionService.persist(response);
                        this.sessionManager.start().subscribe(() => {
                            this.router.navigate(['/app']);
                        }, (error: any) => {
                            this.notification.error('Could not start session');
                        });
                    } else {
                        this.notification.error('Failed to change password');
                    }
                    this.resetting = false;
                    return response;
                }, (error: HttpErrorResponse) => {
                    this.notification.error('Failed to change password');
                    this.resetting = false;
                    return error;
                });
        } else {
            if (!values.password) {
                this.notification.error('Enter new password');
            }
            if (!values.password_confirmation) {
                this.notification.error('Confirm new password');
            }
            if (values.password.length < 6) {
                this.notification.error('Password too short (min. 6 characters)');
            }
        }
    }
}
