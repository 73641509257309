import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PreviewComponent } from './preview/preview.component';
import { TermsRoutes } from './terms.routes';
import { TermsManager } from './terms-manager';
import { TermsResolver } from './terms-resolver';
import { EmbedHtmlComponent } from './embed-html/embed-html.component';

@NgModule({
    providers: [
        TermsManager,
        TermsResolver
    ]
})
export class TermsServiceModule {

}

@NgModule({
    declarations: [
        PreviewComponent,
        EmbedHtmlComponent
    ],
    imports: [
        RouterModule.forChild(TermsRoutes),
        SharedModule,
        TermsServiceModule
    ]
})
export class TermsModule {

}
