import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {

    constructor(private toastrService: ToastrService,
                private translate: TranslateService) {}

    public success(text: string, params: any = {}) {
        this.translate.get(text).subscribe((t: string) => {
            this.toastrService.success(t, '', params);
        });
    }

    public error(text: string, params: any = {}) {
        this.translate.get(text).subscribe((t: string) => {
            this.toastrService.error(t, '', params);
        });
    }
}
