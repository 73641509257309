import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionTokenService } from '../../../auth/service/session-token';
import { SessionManager } from '../../../auth/service/session-manager';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {
    
    constructor(private sessionService: SessionTokenService,
                private sessionManager: SessionManager,
                private router: Router) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.sessionManager.getIdentity()
            .pipe(
                map((response: any) => {
                    return !!response.is_admin;
                })
            );
    }
}
