import { NgModule } from '@angular/core';
import { DropdownDirective } from './directive/dropdown/dropdown.directive';
import { NotificationService } from './notification.service';

@NgModule({
    declarations: [
        DropdownDirective
    ],
    providers: [
        NotificationService,
    ],
    exports: [
        DropdownDirective
    ]
})
export class UiModule {}
