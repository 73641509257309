import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionTokenService } from '../../../auth/service/session-token';
import { HttpConfig } from '../config';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    
    constructor(@Inject(forwardRef(() => SessionTokenService)) private sessionService: SessionTokenService,
                private config: HttpConfig) {
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.sessionService.getToken();
        if (token) {
            const authRequest = req.clone({
                headers: req.headers.set(
                    this.config.authorization.headerName, this.config.authorization.tokenPrefix + ' ' + token.access_token
                )
            });
            
            return next.handle(authRequest);
        }
        
        return next.handle(req);
    }
}
